import Body from "./views/Body";

function App() {
  return (
  <>
  <Body />
  </>
  );
}

export default App;
